import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Hero from '../components/hero/hero'
import siteConfig from '../../data/siteConfig'
import Wrapper from '../components/wrapper'
import PrevNext from '../components/blog/prevnext';


function BlogPost(props) {

  const post = props.data.markdownRemark;
  const { title, date, image } = post.frontmatter;
  const { prev, next, previousBlog, nextBlog } = props.pageContext;
  console.log("props page context" + JSON.stringify(props.pageContext))
  console.log("prev: " + JSON.stringify(prev))
  console.log("next: " + JSON.stringify(next))


  return (
    <Layout>
      <SEO title={title} />
      <Hero
        heroImg={siteConfig.siteCover}
        title={title}
      />
      <Wrapper>
      <div>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <PrevNext prev={previousBlog} next={nextBlog} />
      </div>
      </Wrapper>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
 query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug }}) {
       html
       excerpt
       frontmatter {
        title
        tags
        nextBlog
        previousBlog
       }
   }
   site {
    siteMetadata {
        siteUrl
      }
   }
}
`
