import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {Container, Row, Col} from 'react-bootstrap'

const PrevNext = (props) => {
  const { prev, next } = props
  return (
    <Container>
      <Row>
        <Col>
          {
            prev &&
            <AniLink cover
                     to={prev}
                     direction="right"
                     duration={2}
                     bg="
                        url(/images/cover.jpeg)
                        center / cover /* position / size */
                        no-repeat                /* repeat */
                        fixed                    /* attachment */
                        padding-box              /* origin */
                        content-box              /* clip */
                        white
                      "
            >
              &larr;Previous Post In Series {" "}
            </AniLink>
          }
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          {
            next &&
            <AniLink cover
                     to={next}
                     direction="right"
                     duration={2}
                     bg="
                        url(/images/cover.jpeg)
                        center / cover /* position / size */
                        no-repeat                /* repeat */
                        fixed                    /* attachment */
                        padding-box              /* origin */
                        content-box              /* clip */
                        white
                      "
            >
              Next Post In Series {" "} &rarr;
            </AniLink>
          }
          </Col>
      </Row>
    </Container>
  )
}

export default PrevNext;
